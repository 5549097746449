import React from "react";
import { useTable, useSortBy } from "react-table";
import DownArrowSvg from "../../../../assets/DynamicIcons/DownArrowSvg";
import UpArrowSvg from "../../../../assets/DynamicIcons/UpArrowSvg";
import { roundNumber } from "../../../../services/Helpers/displayTextHelper";
import DroughtImg from "../../../../assets/IndexIcons/Drought.png";
import HeatStressImg from "../../../../assets/IndexIcons/Heat Stress.png";
import ExtremePrecipitationImg from "../../../../assets/IndexIcons/Extreme Precipitation.png";
import WaterStressImg from "../../../../assets/IndexIcons/Water stress.png";
import RiverineFloodingImg from "../../../../assets/IndexIcons/RiverineFlooding.png";
import FlashFloodingImg from "../../../../assets/IndexIcons/PluvialFlooding.png";

const ProjectedClimateRiskTable = ({
	location,
	getRiskColor,
	setChartModal,
	isFinancial,
}) => {
	const columns = React.useMemo(
		() => [
			{
				Header: "Name",
				accessor: "riskType",
				Cell: ({ row }) => (
					<div className="flex gap-2 items-center relative">
						{row.original.riskType}
						{!setChartModal &&
							row.original.riskTypeText === "Water Stress" && (
								<sup className="font-bold text-sm -ml-2">*</sup>
							)}
					</div>
				),
			},
			...(location?.years?.map((year) => ({
				Header: year,
				accessor: year.toString(),
				Cell: ({ value }) => (
					<div
						className={`text-center text-black ${getRiskColor(
							value
						)}`}
					>
						{roundNumber(value)}
					</div>
				),
			})) || []),
		],
		[location]
	);

	const data = React.useMemo(() => {
		const baseColumns = [
			...(!setChartModal
				? []
				: [
						{
							riskType: (
								<div className="ml-8 font-medium">
									<span>Overall Score</span>
								</div>
							),
							riskTypeText: "maxPerils",
							...Object.fromEntries(
								location?.years?.map((year, index) => [
									year.toString(),
									location?.data?.maxPerils?.[index] ??
										"coming soon",
								]) || []
							),
						},
				  ]),
			{
				riskType: (
					<div className="flex gap-2 items-center">
						<img src={DroughtImg} width={25} alt="draughtIcon" />
						<span>Drought</span>
					</div>
				),
				riskTypeText: "Drought",
				...Object.fromEntries(
					location?.years?.map((year, index) => [
						year.toString(),
						location?.data?.Drought?.[index] ?? "coming soon",
					]) || []
				),
			},
			{
				riskType: (
					<div className="flex gap-2 items-center">
						<img
							src={HeatStressImg}
							width={25}
							alt="heatStressIcon"
						/>
						<span>Heat Stress</span>
					</div>
				),
				riskTypeText: "Heat Stress",
				...Object.fromEntries(
					location?.years?.map((year, index) => [
						year.toString(),
						location?.data?.["Heat Stress"]?.[index] ??
							"coming soon",
					]) || []
				),
			},

			{
				riskType: (
					<div className="flex gap-2 items-center">
						<img
							src={WaterStressImg}
							width={25}
							alt="waterStressIcon"
						/>
						<span>Water Stress</span>
					</div>
				),
				riskTypeText: "Water Stress",
				...Object.fromEntries(
					location?.years?.map((year, index) => [
						year.toString(),
						location?.data?.["Water Stress"]?.[index] ??
							"coming soon",
					]) || []
				),
			},
			{
				riskType: (
					<div className="flex gap-2 items-center">
						<img
							src={RiverineFloodingImg}
							width={25}
							alt="riverineFloodIcon"
						/>
						<span>Flood</span>
					</div>
				),
				riskTypeText: "Fluvial Flood",
				...Object.fromEntries(
					location?.years?.map((year, index) => [
						year.toString(),
						location?.data?.["Fluvial Flood"]?.[index] ??
							"coming soon",
					]) || []
				),
			},
			// {
			// 	riskType: (
			// 		<div className="flex gap-2 items-center">
			// 			<img
			// 				src={FlashFloodingImg}
			// 				width={25}
			// 				alt="flashFloodIcon"
			// 			/>
			// 			<span>Pluvial Flood</span>
			// 		</div>
			// 	),
			// 	riskTypeText: "Pluvial Flood",
			// 	...Object.fromEntries(
			// 		location?.years?.map((year, index) => [
			// 			year.toString(),
			// 			location?.data?.["Pluvial Flood"]?.[index] ??
			// 				"coming soon",
			// 		]) || []
			// 	),
			// },
		];
		if (!isFinancial) {
			baseColumns.splice(2, 0, {
				riskType: (
					<div className="flex gap-2 items-center">
						<img
							src={ExtremePrecipitationImg}
							width={25}
							alt="extremePrecipitationIcon"
						/>
						<span>Extreme Precipitation</span>
					</div>
				),
				riskTypeText: "Extreme Precipitation",
				...Object.fromEntries(
					location?.years?.map((year, index) => [
						year.toString(),
						location?.data?.["Extreme Precipitation"]?.[index] ??
							"coming soon",
					]) || []
				),
			});
		}
		return baseColumns;
	}, [location]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data }, useSortBy);

	return (
		<div className="relative overflow-x-auto overflow-y-auto rounded-md border border-gray-300 w-full">
			<table
				{...getTableProps()}
				className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
			>
				<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							className="border-b-[1px] border-gray-300"
						>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(
										column.getSortByToggleProps()
									)}
									className="px-4 py-3 border-l border-gray-300 select-none relative w-1/6"
									align="center"
								>
									{column.render("Header")}
									{column.isSorted && (
										<span className="ml-2 absolute top-2">
											{column.isSortedDesc ? (
												<DownArrowSvg />
											) : (
												<UpArrowSvg />
											)}
										</span>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								className="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
								align="center"
							>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps()}
										className={`px-6 py-4 border-l border-gray-300 ${getRiskColor(
											cell.value
										)} text-black ${
											row.original.riskTypeText ===
											"maxPerils"
												? "font-medium"
												: ""
										}`}
									>
										{cell.render("Cell")}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default ProjectedClimateRiskTable;
