import { captureException } from "@sentry/react";
import axiosHttpService from "../axioscall";

export const getClimateRiskScore = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-climate-risk-score`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getHeatStressHistoric = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-heat-stress-historic`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getHeatStressFuture = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-heat-stress-future`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getWaterStressHistoric = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-water-stress-historic`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getWaterStressFuture = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-water-stress-future`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getExtremePrecipitationHistoric = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-extreme-precipitation-historic`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getExtremePrecipitationFuture = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-extreme-precipitation-future`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getDroughtHistoric = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-drought-historic`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getDroughtFuture = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-drought-future`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getPluvialFluvialHistoric = async (userId, isFluvial) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-pluvial-fluvial-historic`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_fluvial: isFluvial,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getPluvialFluvialFuture = async (
	userId,
	is_585,
	isFluvial = false
) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-pluvial-fluvial-future`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
				is_fluvial: isFluvial,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFutureClimateRiskScore = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-future-climate-risk-score`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFutureClimateRiskPortfolio = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-future-climate-risk-portfolio`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFutureClimateRiskScoreAssetWise = async (
	userId,
	latlon,
	is_585
) => {
	if (!userId || !latlon) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-future-climate-risk-score-asset-wise`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				latlon: latlon,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getClimateRiskChartData = async (
	userId,
	indexName,
	latlon,
	startYear = undefined,
	endYear = undefined,
	isHistoric = true,
	is_585 = "SSP5-8.5"
) => {
	if (!indexName || !latlon) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-historic-chart-data`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				index_name: indexName,
				latlon: latlon,
				start_year: startYear,
				end_year: endYear,
				is_historic: isHistoric,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getVulnerabilityScore = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-climate-vulnerability-score`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFinancialRiskScore = async (userId) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/get-financial-risk-score`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFutureFinancialRiskScore = async (userId, is_585) => {
	if (!userId) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-future-financial-risk-score`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				is_585: is_585 === "SSP5-8.5" ? true : false,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFinancialImpactData = async (
	userId,
	is_585,
	timePeriod,
	climateHazard
) => {
	if (!userId || !is_585 || !timePeriod || !climateHazard) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-financial-impact-data`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				climate_scenario: is_585 === "SSP5-8.5" ? "ssp585" : "ssp245",
				time_period: timePeriod,
				climate_hazard: climateHazard,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getFinancialImpactChartData = async (
	userId,
	scenario,
	level,
	climateHazard
) => {
	if (!userId || !scenario || !level || !climateHazard) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/future-cdp/get-financial-impact-chart-data`,
			method: "GET",
			headers: {},
			params: {
				id: userId,
				climate_scenario: scenario,
				level: level,
				climate_hazard: climateHazard,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};
