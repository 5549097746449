export const conditions = [
	`1. Use of Service: The Service is provided on an "as is" basis. Users must ensure lawful and ethical usage.`,
	`2. User Data & Privacy: We collect and process personal data as per Indian laws. For details, refer to our Privacy Policy.`,
	`3. Intellectual Property: All content, trademarks, and software related to this Service remain the property of Dygnify Ventures Private Limited. Unauthorized use is prohibited.`,
	`4. Payment & Subscription: If applicable, subscription fees are non-refundable. The Service reserves the right to modify pricing with prior notice.`,
	`5. Liability & Warranty Disclaimer: We are not liable for any indirect, incidental, or consequential damages arising from Service use.`,
	`6. Termination & Suspension: We may suspend or terminate accounts violating these terms or applicable laws.`,
	`7. Governing Law & Disputes: These terms are governed by Indian laws, and disputes will be subject to the jurisdiction of Mumbai, Mahrashtra, India.`,
	`8. Modifications: We reserve the right to update these terms at any time. Continued use after changes implies acceptance.`,
];
