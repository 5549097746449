import React, { useContext, useEffect, useState } from "react";
import Map from "../../../components/Map/Map";
import {
	calculateAndAddMaxPerilBaseline,
	getMaxRiskCategoriesOfAssets,
	getRiskColor,
} from "../../../services/Helpers/climateRiskHelper";
import CurrentClimateRiskTable from "./Tables/CurrentClimateRiskTable";
import {
	getDroughtHistoric,
	getExtremePrecipitationHistoric,
	getPluvialFluvialHistoric,
	getHeatStressHistoric,
	getWaterStressHistoric,
} from "../../../services/ApiCalls/climateRiskCall";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import PortfolioTable from "./Tables/PortfolioTable";

const PhysicalClimateRiskScoreCurrent = ({ setLoading }) => {
	const [tableData, setTableData] = useState([]);
	const [locations, setLocations] = useState();
	const { userData } = useContext(LoginContext);

	useEffect(() => {
		(async () => {
			setLoading(true);

			try {
				// Fetch all APIs in parallel
				const [
					droughtRes,
					extremePrecipRes,
					fluvialFloodRes,
					heatStressRes,
					// pluvialFloodRes,
					waterStressRes,
				] = await Promise.allSettled([
					getDroughtHistoric(userData?.id),
					getExtremePrecipitationHistoric(userData?.id),
					getPluvialFluvialHistoric(userData?.id, true),
					getHeatStressHistoric(userData?.id),
					getWaterStressHistoric(userData?.id),
				]);

				// Convert responses to usable data (only keep success responses)
				const responses = {
					Drought:
						droughtRes.status === "fulfilled" &&
						droughtRes.value?.status === "success"
							? droughtRes.value.data
							: [],
					"Extreme Precipitation":
						extremePrecipRes.status === "fulfilled" &&
						extremePrecipRes.value?.status === "success"
							? extremePrecipRes.value.data
							: [],
					"Fluvial Flood":
						fluvialFloodRes.status === "fulfilled" &&
						fluvialFloodRes.value?.status === "success"
							? fluvialFloodRes.value.data
							: [],
					"Heat Stress":
						heatStressRes.status === "fulfilled" &&
						heatStressRes.value?.status === "success"
							? heatStressRes.value.data
							: [],
					// "Pluvial Flood":
					// 	pluvialFloodRes.status === "fulfilled" &&
					// 	pluvialFloodRes.value?.status === "success"
					// 		? pluvialFloodRes.value.data
					// 		: [],
					"Water Stress":
						waterStressRes.status === "fulfilled" &&
						waterStressRes.value?.status === "success"
							? waterStressRes.value.data
							: [],
				};

				// Initialize merged data object
				const mergedData = {};

				Object.keys(responses).forEach((riskCategory) => {
					responses[riskCategory].forEach(
						({ asset, latlon, location, value }) => {
							// If asset is not in mergedData, initialize it
							if (!mergedData[asset]) {
								mergedData[asset] = { asset, latlon, location };
							}
							// Assign the risk category value (fallback to 0 if missing)
							mergedData[asset][riskCategory] = value;
						}
					);
				});

				// Convert merged data back to an array
				const finalData = Object.values(mergedData);

				if (userData?.assets) {
					const maxRisks = getMaxRiskCategoriesOfAssets(finalData);
					const locationsData = getLocationsFromAssets(
						userData?.assets,
						maxRisks
					);
					setLocations(locationsData);
				}
				// Process final data
				const processedData =
					calculateAndAddMaxPerilBaseline(finalData);
				setTableData(processedData);
			} catch (error) {
				console.error("Error fetching climate risk scores:", error);
			}

			setLoading(false);
		})();
	}, [userData]);

	return (
		<div>
			<section className="text-gray-600  px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="text-lg font-bold mb-0 pb-0">
							Portfolio Heat Map
						</div>
						<div>
							Number/percentage of assets in the portfolio exposed
							to various levels of Climate Hazards
						</div>
						<div className="overflow-x-auto mb-8">
							<PortfolioTable tableData={tableData} />
						</div>
						<div className="text-lg font-bold mt-6 mb-4">
							Asset-wise Hazard Score
						</div>
						<div className="overflow-x-auto">
							<CurrentClimateRiskTable
								tableData={tableData}
								getRiskColor={getRiskColor}
							/>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default PhysicalClimateRiskScoreCurrent;
