export const disclaimers = [
	<div>
		1. Modeling Uncertainty – Climate projections involve inherent
		uncertainties due to differences in climate models, emission scenarios,
		and downscaling techniques. Actual climate impacts may vary.
	</div>,
	<div>
		2. Exclusions – The assessment does not incorporate several factors that
		can significantly influence climate risks, including but not limited to:
		<ul className="pl-[50px] list-disc">
			<li className="ml-[5px]">
				Existing flood protection measures (e.g., levees, pumps, very
				localised drainage systems).
			</li>
			<li className="ml-[5px]">
				Plinth level changes and other elevation-based adaptations.
			</li>
			<li className="ml-[5px]">
				Local resilience and adaptation measures already implemented by
				governments, businesses, or communities.
			</li>
			<li className="ml-[5px]">
				Future policy actions or infrastructure investments that may
				mitigate risks.
			</li>
		</ul>
	</div>,
	<div>
		3. Geospatial and Data Limitations – The accuracy of Climate VaR
		calculations depends on the availability and resolution of
		meteorological and geospatial data. Some localized risks may not be
		fully captured. Additionally, latitude and longitude coordinates of
		various assets have been best estimated using the addresses provided by
		the client, which may introduce errors in location accuracy.
	</div>,
	<div>
		4. Industry Classification Assumptions – Industry and sub-industry
		classifications have been taken as provided by the client; however,
		variations in the actual activities of the asset owner may impact
		estimates of potential damages and business interruption losses.
	</div>,
	<div>
		5. Non-Climate Risk Factors – Financial losses due to climate risks may
		be compounded or mitigated by economic, social, and regulatory factors
		that are beyond the scope of this analysis.
	</div>,
	<div>
		6. For Informational Purposes Only – The results of this analysis are
		intended to inform decision-making but should not be considered as
		financial, investment, or legal advice. Users should complement this
		analysis with additional due diligence and expert consultation before
		making strategic decisions.
	</div>,
];
