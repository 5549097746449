import React from "react";
import { useTable, useSortBy } from "react-table";
import { getRiskColorWithCategory } from "../../../../services/Helpers/climateRiskHelper";
import DownArrowSvg from "../../../../assets/DynamicIcons/DownArrowSvg";
import UpArrowSvg from "../../../../assets/DynamicIcons/UpArrowSvg";

const VulnerabilityScoreTable = ({ tableData }) => {
	const data = React.useMemo(() => tableData, [tableData]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Asset",
				accessor: "asset",
			},
			// {
			// 	Header: "District",
			// 	accessor: "District_Display_Proper",
			// },
			{
				Header: "Vulnerability Index",
				accessor: "Vulnerability Index",
			},
			{
				Header: "Category",
				accessor: "Vulnerability Category Index",
			},

			{
				Header: "Key Drivers of Vulnerability",
				accessor: (row) =>
					[
						row["Drivers of Vulnerability.1"],
						row["Drivers of Vulnerability.2"],
						row["Drivers of Vulnerability.3"],
					]
						.filter(Boolean) // Filter out any undefined or null values
						.join(" | "), // Join the non-empty strings with a separator (e.g., " | "),
				Cell: ({ value }) => (
					<div className="text-left">
						{value.split(" | ").map((text, index) => (
							<div key={index}>
								{index + 1}. {text}
							</div>
						))}
					</div>
				),
			},
		],
		[]
	);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns, data }, useSortBy);

	return (
		<>
			{tableData.length ? (
				<div className="relative overflow-x-auto overflow-y-auto max-h-96 rounded-md border border-gray-300 w-full show-scrollbar">
					<table
						{...getTableProps()}
						className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
					>
						<thead className="text-xs text-gray-700 uppercase bg-[#E7EAEE] dark:bg-gray-700 dark:text-gray-400 sticky top-0">
							{headerGroups.map((headerGroup) => (
								<tr
									{...headerGroup.getHeaderGroupProps()}
									className="border-b-[1px] border-gray-300"
								>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps(
												column.getSortByToggleProps()
											)}
											className="px-4 py-3 border-l border-gray-300 select-none relative"
											align="center"
										>
											<div className="flex justify-center items-center">
												{column.render("Header")}
												{column.isSorted && (
													<span className="ml-2 absolute top-3 right-1 pt-[4px]">
														{column.isSortedDesc ? (
															<DownArrowSvg />
														) : (
															<UpArrowSvg />
														)}
													</span>
												)}
											</div>
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										className="bg-neutral-100 border-b dark:bg-gray-800 dark:border-gray-700"
										align="center"
									>
										{row.cells.map((cell) => (
											<td
												{...cell.getCellProps()}
												className={`px-6 py-4 border-l border-gray-300 text-black ${getRiskColorWithCategory(
													cell.value
												)}`}
											>
												{cell.render("Cell")}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<div className="text-neutral-500 text-lg">No data found</div>
			)}
		</>
	);
};

export default VulnerabilityScoreTable;
