import { captureException } from "@sentry/react";
import axiosHttpService from "../axioscall";

export const processRainHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-rain`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processTmaxHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-tmax`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processTminHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-tmin`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processPetHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-pet`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processSpeiHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-spei`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processExtremeHeatDaysHistoric = async (
	email,
	location,
	coordinate
) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-extreme-heat-days`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processHeatIndexHistoric = async (email, location, coordinate) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-heat-index`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processHeatStressHistoric = async (
	email,
	location,
	coordinate
) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-heat-stress`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processExtremePrecipitationHistoric = async (
	email,
	location,
	coordinate
) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-extreme-precipitation`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processWaterStressHistoric = async (
	email,
	location,
	coordinate
) => {
	if (!email || !location || !coordinate) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-water-stress`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processExecuteAllPerils = async (
	email,
	location,
	coordinate,
	auth_token
) => {
	if (!email || !location || !coordinate || !auth_token) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/execute-all`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				location: location,
				coordinate: coordinate,
				auth_token: auth_token,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const processExecuteAllLocationPerils = async (id) => {
	if (!id) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/cdp/process-all-perils`,
			method: "POST",
			headers: {},
			data: {
				id: id,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};
