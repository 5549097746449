import React, { useContext, useEffect, useState } from "react";
import Map from "../../../components/Map/Map";
import { LoginContext } from "../../../LoginProvider/LoginProvider";
import VulnerabilityScoreTable from "./Tables/VulnerabilityScoreTable";
import { getLocationsFromAssets } from "../../../services/Helpers/locationHelper";
import { getVulnerabilityScore } from "../../../services/ApiCalls/climateRiskCall";

const ClimateVulnerabilityScoreCurrent = ({ setLoading }) => {
	const [vulnerabilityScoreTable, setVulnerabilityScoreTable] = useState([]);
	const [locations, setLocations] = useState();
	const [isExpanded, setIsExpanded] = useState(false);
	const { userData } = useContext(LoginContext);
	useEffect(() => {
		(async () => {
			setLoading(true);
			if (userData?.id) {
				const vulnerabilityScore = await getVulnerabilityScore(
					userData?.id
				);
				if (vulnerabilityScore?.status === "success") {
					const riskArray = vulnerabilityScore?.data.map(
						(score) => score?.["Vulnerability Category Index"]
					);
					if (userData?.assets) {
						const locationsData = getLocationsFromAssets(
							userData?.assets,
							riskArray
						);
						setLocations(locationsData);
					}
					setVulnerabilityScoreTable(vulnerabilityScore?.data);
				}
			}
			setLoading(false);
		})();
	}, [userData]);

	const toggleView = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<div>
			<section className="text-gray-600  px-4 pb-4">
				<div className="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
					<div className="p-5">
						<div className="text-lg font-semibold mb-4">
							Definition and context
						</div>
						<div className="">
							<div>
								<span>
									Location wise vulnerability presented below
									assesses climate vulnerability across Indian
									districts, focusing on the&nbsp;
								</span>
								<span className="font-semibold">
									combined effects of climate change,
									socio-economic factors, and environmental
									stressors.&nbsp;
								</span>
								<span>
									The composite vulnerability index is created
									by&nbsp;
								</span>
								<span className="font-semibold">
									integrating various socio-economic
									indicators across multiple sensitivity, and
									adaptive capacity&nbsp;
								</span>
								<span>like:</span>
							</div>
							{isExpanded && (
								<>
									<div className="mt-4">
										<span className="font-semibold">
											1. Climate Hazard
										</span>
										<span>
											&nbsp;– A potentially damaging
											physical event, trend, or impact
											related to climate change. This
											includes extreme weather events
											(e.g., hurricanes, floods,
											heatwaves) and slow-onset changes
											(e.g., sea-level rise,
											desertification).
										</span>
									</div>
									<div>
										<span className="font-semibold">
											2. Exposure
										</span>
										<span>
											&nbsp;– The presence of people,
											assets, infrastructure, ecosystems,
											or economic activities in areas that
											could be affected by climate
											hazards. Higher exposure means more
											elements at risk of damage or
											disruption.
										</span>
									</div>

									<div className="mb-4">
										<span className="font-semibold">
											3. Vulnerability
										</span>
										<span>
											&nbsp; – The susceptibility of
											exposed elements (e.g., communities,
											buildings, economies) to harm from
											climate hazards. Vulnerability
											depends on factors like
											socio-economic conditions,
											infrastructure resilience, and
											adaptive capacity.
										</span>
									</div>
									<div>
										Together, these three elements determine
										<span className="font-semibold">
											climate risk
										</span>
										, which is the potential for loss or
										damage due to climate change.
									</div>
									<div className="mt-4">
										Climate vulnerability, as defined by the
										Intergovernmental Panel on Climate
										Change (IPCC), is a function of
										exposure, sensitivity, and adaptive
										capacity. This assessment evaluates the
										vulnerability of [specific village/point
										location] based on geospatial and
										demographic datasets. The analysis
										incorporates over{" "}
										<span className="font-semibold">
											1,000 spatial and demographic
											parameters across credit,
											infrastructure, bio physical and
											agriculture sectors.
										</span>
									</div>
									<div className="mt-4">
										<span className="font-semibold">
											Methodology{" "}
										</span>
									</div>
									<div>
										The study employs more than 1,000
										parameters across geospatial analysis
										and demographic data to assess climate
										vulnerability. The parameters used in
										the assessment include:
									</div>
									<div>
										<span className="font-semibold">
											Exposure:
										</span>
										&nbsp;Measured by the number of
										households, population density, female
										population distribution, agricultural
										area, and sown area.
									</div>
									<div>
										<span className="font-semibold">
											Sensitivity:
										</span>
										&nbsp;Evaluated based on credit
										availability, bio-physical factors,
										agricultural productivity, soil
										conditions, irrigation infrastructure,
										access to agricultural markets.
									</div>
									<div>
										<span className="font-semibold">
											Adaptive Capacity:
										</span>
										&nbsp;Determined by infrastructure
										accessibility, connectivity
										(electricity, healthcare, education,
										social welfare, internet availability,
										and renewables), financial credit
										parameters (credit growth, new branches,
										branches closed, Banking Correspondents
										(BCs) percentage, rural vs. urban loan
										growth, and agricultural credit trends).
									</div>
									<div>
										The climate vulnerability of point
										location is assessed in a range of 0 to
										1 and graded as&nbsp;
										<span className="font-semibold">
											High/Medium/Low
										</span>
										. The key drivers of vulnerability are
										also provided.
									</div>
								</>
							)}

							<span
								onClick={toggleView}
								className="text-blue-500 underline cursor-pointer"
							>
								{isExpanded ? " less..." : " more..."}
							</span>
						</div>
						<div className="flex justify-center mt-4">
							<div className="h-[400px] w-full bg-white shadow-lg rounded-md border border-gray-200 my-4 mb-8 text-center relative">
								<Map data={locations} />
							</div>
						</div>
						<div className="overflow-x-auto">
							<VulnerabilityScoreTable
								tableData={vulnerabilityScoreTable}
							/>
							<br />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ClimateVulnerabilityScoreCurrent;
