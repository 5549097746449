import { captureException } from "@sentry/react";
import axiosHttpService from "../axioscall";

export const createUserWithEmail = async (email, kycStatus) => {
	if (!email) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users`,
			method: "POST",
			headers: {},
			data: {
				email: email,
				role: 1,
				kycStatus: kycStatus,
				isNewUser: true,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const editUser = async (email, field, value) => {
	if (!email || value === undefined) {
		return undefined;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users`,
			method: "PUT",
			headers: {},
			data: {
				email: email,
				field: field,
				value: value,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
};

export const getUser = async (email, token) => {
	const options = {
		url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users`,
		method: "GET",
		headers: {
			Authorization: "Bearer " + token,
		},
		params: {
			email: email,
		},
	};
	const result = await axiosHttpService(options);
	return result?.res;
};

export const getUserAccountStatus = async (email) => {
	if (!email || email === "") {
		return;
	}
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users/getStatus`,
			method: "GET",
			headers: {},
			params: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		if (result.res.status === "success") {
			return result.res;
		}
		return;
	} catch (error) {
		captureException(error);
	}
};

export async function getAllUsers(pageSize, bookmark) {
	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users/getAllUsers`,
			method: "GET",
			headers: {},
			data: {
				// pageSize: pageSize,
				// bookmark: bookmark,
			},
		};
		const result = await axiosHttpService(options);
		return result.res;
	} catch (error) {
		captureException(error);
	}
}

export const enableDisableUser = async (email) => {
	if (!email || email === "") {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users/enableDisableUser`,
			method: "POST",
			headers: {},
			data: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.status === "success") {
			return result.res;
		} else {
			return;
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
};

export const deleteUser = async (email) => {
	if (!email || email === "") {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users`,
			method: "DELETE",
			headers: {},
			params: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		if (result?.res?.status === "success") {
			return result.res;
		} else {
			return;
		}
	} catch (error) {
		captureException(error);
		throw error;
	}
};

export const forgotPassword = async (email) => {
	if (!email || email === "") {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users/forgotPassword`,
			method: "POST",
			headers: {},
			data: {
				email: email,
			},
		};
		const result = await axiosHttpService(options);
		return result?.res;
	} catch (error) {
		captureException(error);
	}
};

export const uploadExcel = async (excelFile, email) => {
	if (!excelFile || email === "") {
		return;
	}

	try {
		const formData = new FormData();
		formData.append("file", excelFile);
		formData.append("email", email);
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/users/uploadExcel`,
			method: "POST",
			headers: {
				"Content-Type": "multipart/form-data",
			},
			data: formData,
		};
		const result = await axiosHttpService(options);
		return result?.res;
	} catch (error) {
		captureException(error);
	}
};

export const downloadExcel = async (userId) => {
	if (!userId) {
		return;
	}

	try {
		const options = {
			url: `${process.env.REACT_APP_FIREBASE_FUNCTIONS_URI}/excel/download_excel`,
			method: "POST",
			headers: {},
			data: {
				id: userId,
			},
		};
		const result = await axiosHttpService(options);
		return result?.res;
	} catch (error) {
		captureException(error);
	}
};
